/* current element like this */
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: block; }

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 15px;
  height: 15px;
  border: 1px solid #c1c1c1;
  background-color: transparent;
  display: block; }

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  top: 2px; }

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  width: 9px;
  height: 8px;
  font-size: 16px;
  color: #666;
  background: url("../images/sprites.svg") no-repeat 1px -200px;
  transition: all .2s; }

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  top: 7px; }

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

[type="checkbox"]:checked + label:before {
  opacity: 1;
  transform: scale(1);
  border: 1px solid #a5a5a5; }

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #f5f5f5;
  background-color: #f5f5f5; }

[type="checkbox"]:disabled:checked + label:after {
  color: #999; }

[type="checkbox"]:disabled + label {
  color: #aaa; }

/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px dotted blue; }

/* hover style just for information */
label:hover:before {
  border: 1px solid #2f5ea6; }

.right-navbar {
  /* checked mark aspect */ }
  .right-navbar [type="checkbox"]:not(:checked) + label,
  .right-navbar [type="checkbox"]:checked + label {
    width: 100%;
    display: block;
    padding-left: 0; }
  .right-navbar [type="checkbox"]:not(:checked) + label:before, .right-navbar [type="checkbox"]:checked + label:before {
    top: 50%;
    right: 0;
    left: auto;
    margin-top: -7px; }
  .right-navbar [type="checkbox"]:not(:checked) + label:after,
  .right-navbar [type="checkbox"]:checked + label:after {
    top: 18px;
    right: 4px;
    left: auto; }

.select-product {
  /* checkbox aspect */
  /* checked mark aspect */ }
  .select-product [type="checkbox"]:not(:checked) + label,
  .select-product [type="checkbox"]:checked + label {
    position: relative;
    cursor: pointer;
    border: 1px solid #c1c1c1;
    padding: 10px 10px 10px !important;
    width: 1000px;
    display: table-cell;
    vertical-align: middle;
    height: 85px; }
  .select-product [type="checkbox"]:checked + label {
    border: 1px solid #2f5ea6;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); }
  .select-product p {
    line-height: 1.3em; }
  .select-product [type="checkbox"]:not(:checked) + label:before,
  .select-product [type="checkbox"]:checked + label:before {
    border: 0 none;
    bottom: 5px;
    top: auto;
    right: 10px;
    left: auto; }
  .select-product [type="checkbox"]:not(:checked) + label:after,
  .select-product [type="checkbox"]:checked + label:after {
    top: auto;
    bottom: 5px;
    right: 10px;
    left: auto;
    width: 12px;
    height: 10px;
    background: url("../images/sprites.svg") no-repeat -11px -197px; }
  .select-product .table td {
    padding: 5px 0; }

.course [type="checkbox"]:not(:checked) + label,
.course [type="checkbox"]:checked + label {
  padding: 5px; }

#sidebar-countries [type="checkbox"]:not(:checked) + label:after,
#sidebar-countries [type="checkbox"]:checked + label:after {
  top: 23px; }

.tnc [type="checkbox"]:not(:checked) + label:after,
.tnc [type="checkbox"]:checked + label:after {
  top: 7px !important; }
